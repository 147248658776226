<section class="text-center view jarallax">
  <!-- Section heading -->
  <h2 class="white-text h1-responsive font-weight-bold my-5">Photo Work</h2>
  <!-- Section description -->

  <!-- Grid row -->
  <div class="row mb-5 px-3">

    <!-- Grid column -->
    <div class="colhov col-lg col-md-12 mb-lg-0 pt-3 pb-4 ml-1">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="../../assets/images/Joyryde_FrontOfHouse.jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!-- Category -->
      <a href="#!" class="text-warning">
        <h6 class="font-weight-bold mb-3"><i class="fas fa-headphones"></i></h6>
      </a>
      <!-- Post title -->
      <h4 class="white-text font-weight-bold mb-3"><strong>EDM Work</strong></h4>
      <!-- Excerpt -->
      <p class="white-text">Covering tour stops, concerts, festivals and behind the scenes videos with artists and producers in the EDM Industry.</p>
      <!-- View more button -->
      <a [routerLink]="['/photo']" routerLinkActive="router-link-active" mdbBtn class="btn btn-outline-warning btn-rounded waves-effect" mdbWavesEffect>View more</a>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="colhov col-lg col-md-6 mb-md-0 pt-3 pb-4">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="../../assets/images/mastro-pipe.jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!-- Category -->
      <a href="#!" class="text-warning">
        <h6 class="font-weight-bold mb-3"><i class="fas fa-graduation-cap pr-2"></i></h6>
      </a>
      <!-- Post title -->
      <h4 class="white-text font-weight-bold mb-3"><strong>Action Sports</strong></h4>
      <!-- Excerpt -->
      <p class="white-text">Capturing both the trick and all of the behind-the-scenes work that goes into it.</p>
      <!-- View more button -->
      <a [routerLink]="['/photo']" routerLinkActive="router-link-active" mdbBtn class="btn btn-outline-warning btn-rounded waves-effect" mdbWavesEffect>View more</a>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="colhov col-lg col-md-6 pt-3 pb-4 mr-1">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="../../assets/images/rachel-seaport.jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

      <!-- Category -->
      <a href="#!" class="text-warning">
        <h6 class="font-weight-bold mb-3"><i class="fas fa-fire pr-2"></i></h6>
      </a>
      <!-- Post title -->
      <h4 class="white-text font-weight-bold mb-3"><strong>Portraits and Moments</strong></h4>
      <!-- Excerpt -->
      <p class="white-text">Compilations of great times I've had my camera rolling alongside my closest friends.</p>
      <!-- View more button -->
      <a [routerLink]="['/photo']" routerLinkActive="router-link-active" mdbBtn class="btn btn-outline-warning btn-rounded waves-effect" mdbWavesEffect>View more</a>

    </div>
    <!-- Grid column -->

  </div>

  <!-- Grid row -->

  <p class="white-text w-responsive mx-auto clients">Clients: Woodward Camp, UMass Amherst - Isenberg School of Management, Lit Nightclub, Icon Nightclub, Olde Town Tavern, The Spoke Amherst, Monkeybar Amherst, Element 24, UMass SK Sorority, UMass KKG Sorority</p>
</section>
