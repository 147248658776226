import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PhotoOverlayComponent } from './photo-overlay/photo-overlay.component';
@Component({
  selector: 'app-photo-page',
  templateUrl: './photo-page.component.html',
  styleUrls: ['./photo-page.component.scss']
})
export class PhotoPageComponent implements OnInit {
  photos: any[] = [];
  photoRows: any[] = [];

  constructor( public dialog: MatDialog) {
    this.photos = [
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/cashcash.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      // tslint:disable-next-line:max-line-length
      { 'image': '../../assets/images/rico.jpg', 'tag': 'portrait' }, { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/spacecade.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/rico.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/cashcash.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      // tslint:disable-next-line:max-line-length
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' }, { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' },
      { 'image': '../../assets/images/Joyryde_FrontOfHouse.jpg', 'tag': 'edm' },
      { 'image': '../../assets/images/mastro-pipe.jpg', 'tag': 'sports' },
      { 'image': '../../assets/images/rachel-seaport.jpg', 'tag': 'portrait' }
    ];
    for (let i = 0; i < this.photos.length; i += 3 ) {
      this.photoRows.push({ pics: this.photos.slice(i, i + 3) });
    }
  }

  ngOnInit() {
  }

  callOverlay(image: String) {
    const deeperImage = '../' + image;
    const photoDialogRef = this.dialog.open(PhotoOverlayComponent, {
      height: '90vh',
      width: 'auto',
      data: deeperImage
    });
    photoDialogRef.afterClosed().subscribe(result => {
      console.log('Do stuff on close if you want');
    });
  }

}
