
<!-- Main navigation -->
<div class="wrapper">
<cdk-virtual-scroll-viewport itemSize=300>
<header>
  <!-- Full Page Intro -->

  <div class="view jarallax landing-background">
    <video autoplay [muted]="true" loop id="myVideo">
      <source src="../../assets/Gryffin BCSS Web.mp4" type="video/mp4">
    </video>
    <!-- Mask & flexbox options-->
    <div class="mask rgba-black-light d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="text-center">
            <h1 [@load]="isLoaded ? 'loaded' : 'loading'" class="h1-reponsive white-text text-uppercase font-weight-bold mb-0 pt-md-5 pt-5 fade-in name-title"><strong>Ryan LeCours</strong></h1>
            <hr [@load]="isLoaded ? 'loaded' : 'loading'" class="hr-light my-4">
            <h5 [@load]="isLoaded ? 'loaded' : 'loading'" class="text-uppercase mb-4 white-text videogphotog" data-wow-delay="0.4s">Videographer | Photographer</h5>
            <span class="pageLinks">
            <a [routerLink]="['/video']" routerLinkActive="router-link-active" class="btn btn-rounded z-depth-2">Video</a>
            <a [routerLink]="['/photo']" routerLinkActive="router-link-active" class="btn btn-rounded z-depth-2">Photo</a>
            <a [routerLink]="['/']" routerLinkActive="router-link-active" class="btn btn-rounded contact-me z-depth-2">Contact me</a>
          </span>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>
  <!-- Full Page Intro -->
</header>
<!-- Main navigation -->
<video-section></video-section>
<photo-section></photo-section>
</cdk-virtual-scroll-viewport>
</div>
