<ng-container class="container">
  <div class="pt-5 pb-3 header">
    <div class="title">
      <h1 class="white-text px-auto">Freelance Photography</h1>
    </div>
  </div>
 <cdk-virtual-scroll-viewport itemSize="300">
  <div class="row mt-3">
    <p class="white-text text-center description">Below you will find my most recent projects and portraits for clients and friends.</p>
</div>
    <div *cdkVirtualFor="let r of photoRows; let i = index" class="row">
      <ng-container *ngFor="let p of r?.pics">
    <li  class="animated fadeIn mx-auto">
      <img (click)="callOverlay(p.image)" class="img-fluid mx-auto" src={{p.image}}>
    </li>
  </ng-container>
  </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
