<div id="container" class="view jarallax">
  <div class="row pt-5 header">
    <div class="title pb-3">
      <h1 class="white-text px-auto">Freelance Videography</h1>
    </div>
  </div>
  <cdk-virtual-scroll-viewport itemSize="300">
    <div class="row mt-3">
        <p class="white-text text-center description">Below you will find my most recent projects with EDM Producers like Joyryde,
          Ghastly, Boslyk, Fisher and more.</p>
    </div>
      <div *cdkVirtualFor="let r of videoRows; let i = index" class="videos">
        <ng-container class="videoRow" *ngFor="let v of r?.vids; let x = index">
          <div class="offset-1 oddOffset" *ngIf="x%2===0"></div>
          <div class="col-5 video-container">
            <iframe class="animated fadeInDown" width="100%" height="450px" [src]="v.source | sanitizeHtml"
              frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </ng-container>
      </div>
  </cdk-virtual-scroll-viewport>
</div>
