  
<nav class="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
  <a class="navbar-brand" [routerLink]="['/']" routerLinkActive="router-link-active" >Ryan LeCours</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto full-width">
      <li class="nav-item active my-auto ml-4">
        <a class="nav-link" [routerLink]="['/']" routerLinkActive="router-link-active" >Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item my-auto ml-4">
        <a class="nav-link" [routerLink]="['/video']" routerLinkActive="router-link-active" >Video</a>
      </li>
      <li class="nav-item my-auto ml-4">
        <a class="nav-link" [routerLink]="['/photo']" routerLinkActive="router-link-active" >Photo</a>
      </li>
      <li class="nav-item my-auto ml-auto mr-3 ml-auto">
          <a class="nav-link" href="#">Contact Me</a>
        </li>
    </ul>
  </div>
</nav>