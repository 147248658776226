<section class="text-center white-text pb-3">
  <div class="py-5">
  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold">Video Work</h2>
  <!-- Section description -->

  <!-- EDM Grid row -->
  <div class="row ml-5 pl-3 my-3 py-3">


      <!-- Featured image -->
      <div class="col-lg-5 col-md-6 video-container">
          <iframe class="z-depth-2" width="100%" height="100%" src="https://www.youtube.com/embed/ltaqOyij0jo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope" allowfullscreen></iframe>
        <a [routerLink]="['/video']" routerLinkActive="router-link-active" >
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>
    
<div class="video-info mt-auto mb-auto">
      <!-- Category -->
      <a [routerLink]="['/video']" routerLinkActive="router-link-active"  class="text-warning">
        <h4 class="font-weight-bold mb-3"><i class="fas fa-headphones"></i></h4>
      </a>
      <!-- Post title -->
      <h4 class="font-weight-bold mb-3"><strong>EDM Work</strong></h4>
      <!-- Excerpt -->
      <p>Covering tour stops, concerts, festivals and behind the scenes videos with artists and producers in the EDM Industry.</p>
      <!-- Read more button -->
      <a [routerLink]="['/video']" routerLinkActive="router-link-active"  mdbBtn class="btn btn-outline-warning btn-rounded waves-effect see-all" mdbWavesEffect>See all</a>
  </div>
  </div> <!--Row End-->
  <!-- Sports Grid row -->
  <div class="row ml-5 pl-3 my-3 py-3">
    <!-- Grid column -->
    <div class="col-lg-5 col-md-6 video-container">

      <!-- Featured image -->
          <iframe class="z-depth-2" width="100%" height="100%" src="https://www.youtube.com/embed/mtUCwfJ5630" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
    </div>
    <div class="video-info mt-auto mb-auto">
      <!-- Category -->
      <a href="#!" class="text-warning mt-3">
        <h4 class="font-weight-bold mb-3"><i class="fas fa-graduation-cap pr-2"></i></h4>
      </a>
      <!-- Post title -->
      <h4 class="font-weight-bold mb-3"><strong>Action Sports</strong></h4>
      <!-- Excerpt -->
      <p>Behind the lens with some of the best snowboarders on the east coast.</p>
      <!-- Read more button -->
      <a [routerLink]="['/video']" routerLinkActive="router-link-active"  mdbBtn class="btn btn-outline-warning btn-rounded waves-effect see-all" mdbWavesEffect>See all</a>

   
    </div> <!--Row end-->
  </div>
    <!--Fun Grid row -->
    <div class="row ml-5 pl-3 my-3 py-3">
    <!-- Grid column -->
    <div class="col-lg-5 col-md-6 video-container">

      <!-- Featured image -->
          <iframe class="z-depth-2" width="100%" height="100%" src="https://www.youtube.com/embed/f85N8rWcqqM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
    </div>
      <div class="video-info mt-auto mb-auto">
      <!-- Category -->
      <a href="#!" class="text-warning">
        <h4 class="font-weight-bold mb-3"><i class="fas fa-fire"></i></h4>
      </a>
      <!-- Post title -->
      <h4 class="font-weight-bold mb-3"><strong>Just for Fun</strong></h4>
      <!-- Excerpt -->
      <p>Compilations of great times I've had my camera rolling alongside my closest friends.</p>
      <!-- Read more button -->
      <a [routerLink]="['/video']" routerLinkActive="router-link-active"  mdbBtn class="btn btn-outline-warning btn-rounded waves-effect see-all" mdbWavesEffect>See all</a>
</div>
    
</div>
</div>
  <!-- Grid row -->
  
  <p class="clients mx-auto">Clients: Boat Cruise Summer Series, Raw Music, Royale Nightclub, Woodward Camp, Spring Fest Amherst, Lit Nightclub, Rezinate, Uncle Bob Presents, UMass Amherst - Isenberg School of Management, UMass KKG Sorority, UMass SK Sorority, UMass AXO Fraternity, Monkeybar Amherst</p>
</section>
