import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { OverlayRef, PositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'photo-overlay',
  templateUrl: './photo-overlay.component.html',
  styleUrls: ['./photo-overlay.component.scss']
})
export class PhotoOverlayComponent implements OnInit {
overlayRef: OverlayRef;
overlayPosition: PositionStrategy;
// formComponentPortal: ComponentPortal<FormComponent>;
  constructor(public dialogRef: MatDialogRef<PhotoOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data: String) {
   }

  ngOnInit() {
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
