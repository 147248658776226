import { Component, OnInit, HostBinding, AfterViewInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'landing-section',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.scss'],
  animations: [
    trigger('load', [
    state('loading', style({
      opacity: 0
    })),
    state('loaded', style({
      opacity: 1
    })),
    transition('loading => loaded', [
      animate('1000ms')
    ]),
  ]),
  ]
})
export class LandingSectionComponent implements AfterViewInit {
  isLoaded = false;
  constructor() { }

  ngAfterViewInit(): void {
   this.toggle();
  }

  toggle() {
    this.isLoaded = true;
  }

}
