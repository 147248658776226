import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { VideoSectionComponent } from './video-section/video-section.component';
import { LandingSectionComponent } from './landing-section/landing-section.component';
import { PhotoSectionComponent } from './photo-section/photo-section.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PhotoOverlayComponent } from './photo-page/photo-overlay/photo-overlay.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    VideoSectionComponent,
    LandingSectionComponent,
    PhotoSectionComponent,
    NavigationBarComponent,
    routingComponents,
    PhotoOverlayComponent,
    SanitizeHtmlPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ScrollingModule,
    OverlayModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatDialogModule
  ],
  exports: [SanitizeHtmlPipe],
  providers: [],
  entryComponents: [
    PhotoOverlayComponent
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
