import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss']
})
export class VideoPageComponent implements OnInit {
  videos: any[] = [];
  videoRows: any[] = [];
  constructor() {
    this.videos = [
      { 'source': 'https://www.youtube.com/embed/uh1F_XWGZ4o', 'tag': 'portrait' },
      { 'source': 'https://www.youtube.com/embed/x7pub7-Ovys', 'tag': 'edm' },
      { 'source': 'https://www.youtube.com/embed/XpF2C_QCDgE', 'tag': 'edm' },
      { 'source': 'https://www.youtube.com/embed/ltaqOyij0jo', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/5cyinJaIdQA', 'tag': 'edm' },
      { 'source': 'https://www.youtube.com/embed/z5vMoiNwnzc', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/C7OtndRrHSY', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/BWKXFS_zYVY', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/bbNCZHNqkOE', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/B6JZCWfTTYk', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/3XvspmAAb8s', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/-XaKiY4zcP8', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/JspN1W1fnF8', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/f85N8rWcqqM', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/jdS5qmFeSGc', 'tag': 'sports' },
      { 'source': 'https://www.youtube.com/embed/JbL_1pMjle4', 'tag': 'sports' }
    ];
    for (let i = 0; i < this.videos.length; i += 2 ) {
      this.videoRows.push({ vids: this.videos.slice(i, i + 2) });
    }
   }

  ngOnInit() {
  }

}

