import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhotoPageComponent } from './photo-page/photo-page.component';
import { VideoPageComponent } from './video-page/video-page.component';
import { LandingSectionComponent } from './landing-section/landing-section.component';

const routes: Routes = [
    {path: '', component: LandingSectionComponent},
    {path: 'video', component: VideoPageComponent},
    {path: 'photo', component: PhotoPageComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [VideoPageComponent, PhotoPageComponent, LandingSectionComponent];
